$(document).ajaxStop($.unblockUI);

const messageReturn = (title, message, type_message, url) => {
    Swal.fire({
        title: title,
        html: message,
        type: type_message,
        // confirmButtonClass: 'btn btn-primary',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 2000,
    }).then((result) => {
        if (url != '' && url != undefined) {
            window.location.assign(url);
        } else {
            // location.reload();
        }

    });
}

// if ($("login-form").attr("novalidate") != undefined) {
$('#login-form').find('input,select,textarea').not("[type=submit]").jqBootstrapValidation({
    preventSubmit: true,
    submitSuccess: function ($form, event) {
        event.preventDefault();
        var formData = {
            'email': $('input[name=email]').val(),
            'password': $('input[name=password]').val()
        };
        $.ajax({
            url: $form.attr('action'),
            method: $form.attr('method'),
            data: formData,
            dataType: 'json',
            beforeSend: function () {
                showLoadingScreen();
            },
            success: function (response) {
                $.unblockUI();
                $('#login-form')[0].reset();
                messageReturn(response.title, response.message, response.type_message, response.url);
            },
            error: function (response, error) {
                // console.log(response);
            }
        });
    }
});

function showLoadingScreen()
{
    //include block.js for using this
    $.blockUI({ 
        message: '<div class="ft-refresh-ccw icon-spin" style="font-size:30px;"></div>',
            overlayCSS: {
                backgroundColor: "#fff",
                opacity: 0.8,
                cursor: "wait",
                '-webkit-border-radius': '5px',
                '-moz-border-radius': '5px'
            },
            css: {
                color: "#000",
                border: 0,
                padding: 0,
                backgroundColor: "transparent"
            },
            onOverlayClick: $.unblockUI
    }); 
}